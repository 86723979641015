import React from "react";

const Contact = () => {
  return (
    <div className="content-container">
      <h1 className="page-heading">CONTACT</h1>
      <div>
        <p style={{ marginBottom: "50px" }}>
          Get in touch to book <span>NIGHT MOVES</span> for your special day!
        </p>
        <div style={{ padding: "40px", backgroundColor: "white" }}>
          <p>
            Declan Ph. 086 887 7425
            <br />
            declanlandy@hotmail.com
          </p>
          <p>
            Austin Ph. 087 192 9429
            <br />
            austinokeeffe1@gmail.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;

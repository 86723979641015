import React from "react";

const Footer = () => {
  return (
    <footer
      style={{ backgroundColor: "black", color: "white", padding: "20px 0" }}
    >
      <div style={{ textAlign: "center" }}>
        <p>
          Contact Us <br />
          <br /> Declan: Phone 086 887 7425 | Email declanlandy@hotmail.com{" "}
          <br /> Austin: Phone 087 192 9429 | Email austinokeeffe1@gmail.com
        </p>
        <p>NIGHT MOVES - All rights reserved</p>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Weddings from "./pages/Weddings";
import Video from "./pages/Video";
import Setlist from "./pages/Setlist";
import Testimonials from "./pages/Testimonials";
import Contact from "./pages/Contact";
import "./App.css";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

function App() {
  return (
    <Router>
      <div id="page-container">
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/weddings" element={<Weddings />} />
          <Route path="/videos" element={<Video />} />
          <Route path="/setlist" element={<Setlist />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

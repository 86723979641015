import React from "react";

const Testimonials = () => {
  return (
    <div className="content-container">
      <h1 className="page-heading">TESTIMONIALS</h1>
      <p style={{ textAlign: "center", marginBottom: "50px" }}>
        See what some of the happy couples who booked <span>NIGHT MOVES</span>{" "}
        have to say about us!
      </p>
      <div className="testimonials-box">
        <p style={{ color: "black", fontFamily: "Times" }}>
          'NIGHT MOVES are a brilliant band! They played a huge range of songs
          that kept our guests, young and old, happy. The interaction with the
          crowd was really good and the dance floor was packed all night. The DJ
          was fantastic too. I would highly recommend them to anyone!'
        </p>
        <p style={{ color: "black", fontWeight: "bold", fontSize: "18px" }}>
          Amanda & Graham
          <br />
          The Heritage Hotel, Laois
        </p>
      </div>

      <div className="testimonials-box">
        <p style={{ color: "black", fontFamily: "Times" }}>
          'NIGHT MOVES were absolutely epic at our wedding. The floor was full
          from the very first song. They took all of our requests into
          consideration and played all of the special songs we wanted. The
          feedback we have gotten from our guests is fantastic. As for value for
          money, they were second to none and worth every single penny!'
        </p>
        <p style={{ color: "black", fontWeight: "bold", fontSize: "18px" }}>
          Melanie & Sean
          <br />
          Lawlor's Hotel
        </p>
      </div>
      <div className="testimonials-box">
        <p style={{ color: "black", fontFamily: "Times" }}>
          'A massive thank you to NIGHT MOVES - our wedding guests were worn out
          from dancing. From the first dance to the end of the DJ's set, we had
          a blast! Everyone commented on the quality of the band, they were
          super lively, and the sound was amazing too. We would recommend them
          to anyone.'
        </p>
        <p style={{ color: "black", fontWeight: "bold", fontSize: "18px" }}>
          Deirde & Ronan
          <br />
          Clontarf Castle Hotel
        </p>
      </div>
    </div>
  );
};

export default Testimonials;

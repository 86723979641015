import React from "react";

const Home = () => {
  return (
    <div className="content-container">
      <div className="container">
        <div className="text-container">
          <div style={{ flex: 1 }}>
            <p className="text">
              <span>NIGHT MOVES</span> are an established touring band that
              cater for all types of events, such as weddings, corporate
              functions, and parties.
            </p>
            <p className="text">
              We provide a full <span>live show</span> plus DJ, playing all the
              hits to give you and your guests a celebration to remember.
            </p>
            <p className="text">
              We are some of Ireland's most experienced musicians, with years of
              playing in theatres, venues, and hotels around Ireland.
            </p>
            <p className="text">
              The party starts with <span>NIGHT MOVES</span>, so don't delay and
              book today!
            </p>
            <p className="text" style={{ textAlign: "center" }}>
              Call Declan on 086 887 7425
              <br />
              or
              <br />
              Austin on 087 192 9429
            </p>
          </div>
        </div>
        <div className="image-container">
          <img
            src="../../images/homepage-1.jpeg"
            alt="The Band"
            className="image"
          />
        </div>
      </div>
      <div id="grid">
        <div style={{ display: "flex", width: "100%" }}>
          <div id="pics-col-1" style={{ flex: "1", paddingLeft: "0px" }}>
            <img
              src="../../images/declan-homepage.jpeg"
              alt="Declan"
              className="homepage-img-grid"
            />
          </div>
          <div
            id="pics-col-2"
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <img
              src="../../images/austin-homepage-new.jpeg"
              alt="Austin"
              className="homepage-img-grid"
            />
            <img
              src="../../images/keyboards-homepage-new.jpg"
              alt="Wedding"
              className="homepage-img-grid"
            />
          </div>
          <div id="pics-col-3" style={{ flex: "1", paddingRight: "0px" }}>
            <img
              src="../../images/donna-homepage.jpeg"
              alt="Donna"
              className="homepage-img-grid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

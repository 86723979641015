import React from "react";

const Setlist = () => {
  return (
    <div className="content-container">
      <h1 className="page-heading">SETLIST</h1>
      <p style={{ textAlign: "center", marginBottom: "50px" }}>
        <span>NIGHT MOVES</span>'s setlist is amazingly versatile, crammed with
        hits from the 60s to today's dance songs. We are also happy to learn any
        special songs you might want specifically for your big day, from first
        dance to last. The DJ will also happily play any requests. Have a look
        below to see a sample setlist of what we play.
      </p>
      <div className="setlist-container">
        <div className="setlist-row">
          <section className="setlist-section">
            <h2>Standards</h2>
            Proud Mary - Tina Turner <br />
            River Deep Mountain High - Tina Turner <br />
            Sweet Caroline - Neil Diamond <br />
            Brown Eyed Girl - Van Morrison <br />
            My Girl - The Temptations <br />
            Higher and Higher - Jackie Wilson <br />
            I'm a Believer - The Monkees <br />
            Be My Baby - The Ronettes <br />
            Downtown - Petula Clark <br />
            I Saw Her Standing There - The Beatles <br />
            Build Me Up Buttercup - The Foundations
          </section>
          <section className="setlist-section">
            <h2>Charts</h2>
            Flowers - Miley Cyrus <br />
            Blinding Lights - The Weeknd <br />
            Shotgun - George Ezra <br />
            Dance Monkey - Tones And I <br />
            Shape of You - Ed Sheeran <br />
            Uptown Funk - Bruno Mars <br />
            Rolling in the Deep - Adele <br />
            Wake Me Up - Avicii <br />
            Shut Up and Dance - Walk The Moon <br />
            Shake It Off - Taylor Swift <br />
            Get Lucky - Daft Punk
          </section>
        </div>
        <div className="setlist-row">
          <section className="setlist-section">
            <h2>60s</h2>
            Suspicious Minds - Elvis Presley <br />
            Return to Sender - Elvis Presley <br />
            Oh, Pretty Woman - Roy Orbison <br />
            Johnny B. Goode - Chuck Berry <br />
            You Never Can Tell - Chuck Berry <br />
            Da Doo Ron Ron - The Crystals <br />
            Twist and Shout - The Beatles <br />
            Runaway - Dell Shannon <br />
            Whole Lotta Shakin' - Jerry Lee Lewis <br />
            It's Not Unusual - Tom Jones <br />
            Ring of Fire - Johnny Cash
          </section>
          <section className="setlist-section">
            <h2>70s</h2>
            Blame it on the Boogie - The Jackson 5 <br />
            Momma Mia - ABBA <br />
            Does Your Mother Know - ABBA <br />
            Dancing Queen - ABBA <br />
            Don't Stop Me Now - Queen <br />
            Crazy Little Thing Called Love - Queen <br />
            I Will Survive - Gloria Gaynor <br />
            Superstition - Stevie Wonder <br />
            December, 1963 (Oh, What A Night) - Frankie Valli & The Four Seasons
            <br />
            Make Me Smile (Come Up and See Me) - Steve Harley & Cockney Rebel{" "}
            <br />
            Maggie May - Rod Stewart <br />
            Burning Love - Elvis Presley <br />
            Stuck in the Middle With You - Steelers Wheel <br />
            Rockin' All Over the World - Status Quo
          </section>
        </div>
        <div className="setlist-row">
          {" "}
          <section className="setlist-section">
            <h2>80s</h2>
            Just Can't Get Enough - Depeche Mode <br />
            You Can Call Me Al - Paul Simon <br />
            A Little Respect - Erasure <br />
            Simply The Best - Tina Turner <br />
            Summer of '69 - Bryan Adams <br />
            Take On Me - A-ha <br />
            Wake Me Up Before You Go-Go - Wham! <br />
            Don't Stop Believing - Journey <br />
            I Wanna Dance With Somebody - Whitney Houston <br />
            Billie Jean - Michael Jackson <br />
            Dancing in the Dark - Bruce Springsteen <br />
            500 Miles - The Proclaimers <br />
            I'm Still Standing - Elton John <br />
            Walking on Sunshine - Katrina And The Waves <br />
            Gimme Hope Jo'anna - Eddy Grant <br />
            Girls Just Wanna Have Fun - Cyndi Lauper <br />
            Venus - Bananarama <br />
            Uptown Girl - Billy Joel <br />
            Livin' on a Prayer - Bon Jovi <br />
            Walk of Life - Dire Straits <br />
            Sweet Child O' Mine - Guns N' Roses <br />
            Tainted Love - Soft Cell
          </section>
          <section className="setlist-section">
            <h2>90s - Today</h2>
            Let Me Entertain You - Robbie Williams <br />
            Man! I Feel Like A Woman! - Shania Twain <br />
            She's Electric - Oasis <br />
            Wannabe - Spice Girls <br />
            Freed From Desire - Gala <br />
            Relight My Fire - Take That <br />
            Rhythm Is a Dancer - Snap! <br />
            Step On - Happy Mondays <br />
            What Is Love - Haddaway <br />
            Moving On Up - M People <br />
            Mr. Brightside - The Killers <br />
            Human - The Killers <br />
            Sex on Fire - Kings of Leon <br />
            Can't Stop The Feeling! - Justin Timberlake <br />
            Castle on the Hill - Ed Sheeran <br />
            World of Our Own - Westlife <br />
            Teenage Dirtbag - Wheatus <br />
            Chelsea Dagger - The Fratellis <br />
            I'm Yours - Jason Mraz <br />
            Little Lion Man - Mumford & Sons
          </section>
        </div>
        <div className="setlist-row">
          <section className="setlist-section">
            <h2>Irish/Country</h2>
            Galway Girl - Steve Earle <br />
            Giants - Dermot Kennedy <br />
            Wagon Wheel - Darius Rucker <br />
            I Useta Lover - The Saw Doctors <br />
            Matrimony - Gilbert O'Sullivan <br />
            Dance The Night Away - The Mavericks <br />
            Good Looking Woman - Joe Dolan <br />
            The Gambler - Kenny Rogers <br />
            Crazy World - Aslan <br />
            Zombie - The Cranberries <br />
            Achy Breaky Heart - Billy Ray Cyrus <br />
            I Fall To Pieces - Patsy Cline <br />
            Country Roads - John Denver
          </section>
          <section className="setlist-section">
            <h2>First Dances</h2>
            Perfect - Ed Sheeran <br />
            Thinking Out Loud - Ed Sheeran <br />
            I've Had The Time of My Life - Dirty Dancing <br />
            Have I Told You Lately That I Love You - Van Morrison <br />
            Wonderful Tonight - Eric Clapton <br />
            Angels - Robbie Williams <br />
            A Thousand Years - Christina Perri <br />
            Your Song - Elton John <br />
            My Love - Paul McCartney <br />
          </section>
        </div>
        <div className="setlist-row">
          <section className="setlist-section">
            <h2>Waltzes</h2>
            Que Sera, Sera - Doris Day <br />
            That's Amore - Dean Martin <br />
            Red Rose Cafe - The Fureys <br />
            Piano Man - Billy Joel <br />
            Wild Rover - The Wolfe Tones <br />
            Delilah - Tom Jones
          </section>
          <section className="setlist-section">
            <h2>Christmas</h2>
            Fairytale of New York - The Pogues featuring Kirsty MacColl <br />
            Last Christmas - Wham! <br />
            Rockin' Around The Christmas Tree - Brenda Lee <br />
            Blue Christmas - Elvis Presley <br />
            Driving Home for Christmas - Chris Rea <br />
            All I Want For Christmas Is You - Mariah Carey <br />
            Auld Lang Syne - Traditional
          </section>
        </div>
      </div>
    </div>
  );
};

export default Setlist;

import React from "react";

const Video = () => {
  return (
    <div className="content-container">
      <h1 className="page-heading">VIDEO</h1>
      <iframe
        className="video"
        src="https://www.youtube.com/embed/wpbPkGsj9Vs?si=M1A09riKpBoTv-Tw"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Video;

import React from "react";

const Weddings = () => {
  return (
    <div className="content-container">
      <h1 className="page-heading">WEDDINGS</h1>
      <div style={{ marginBottom: "40px" }}>
        <p>
          Hello there!
          <br />
          <br />
          We are Donna, Declan, and Austin by day, AKA <span>
            NIGHT MOVES
          </span>{" "}
          by night. We are a well established wedding and corporate band with
          decades of professional experience, well known for our lively setlist
          playing everything you love from the 60s to today's banging
          floor-fillers.
        </p>
        <p>
          It's all about the music with <span>NIGHT MOVES</span>, so why not get
          in touch and let us let you know how we can tailor your special day,
          your first dance, your live show requests, and the DJ songs you love.
        </p>
        <p>
          With live band and DJ we will perform 3 hours of non-stop anthems,
          bringing out those night moves in all your guests and filling the
          dance floor well into the early hours.
        </p>
        <p>
          We promise to make the biggest day of your life, the best day of your
          life.
        </p>
      </div>
      <div id="wedding-images-container">
        <img
          src="../../images/wedding-1.jpeg"
          alt="Happy Couple 1"
          className="wedding-image"
        />
        <img
          src="../../images/wedding-2.jpeg"
          alt="Happy Couple 2"
          className="wedding-image"
        />
      </div>
    </div>
  );
};

export default Weddings;

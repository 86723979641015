import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div>
        <div id="navbar-top">
          <div class="navbar-logo-container">
            <Link to="/" onClick={handleLinkClick}>
              <img
                src="../../images/night-moves-logo.png"
                alt="Logo"
                className="navbar-logo"
              />
            </Link>
          </div>
          <p className="navbar-description">
            WEDDING BAND & CORPORATE EVENT ENTERTAINMENT IN IRELAND
          </p>
          <div className="hamburger-menu" onClick={toggleMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </div>
      </div>

      <div
        // id="navbar-bottom"
        className={
          windowWidth <= 768
            ? isMenuOpen
              ? "navbar-dropdown-open"
              : "navbar-dropdown-closed"
            : "navbar-full-size"
        }
      >
        <Link to="/" className="navbar-link" onClick={handleLinkClick}>
          <button>Home</button>
        </Link>
        <Link to="/weddings" className="navbar-link" onClick={handleLinkClick}>
          <button>Weddings</button>
        </Link>
        <Link to="/videos" className="navbar-link" onClick={handleLinkClick}>
          <button>Videos</button>
        </Link>
        <Link to="/setlist" className="navbar-link" onClick={handleLinkClick}>
          <button>Setlist</button>
        </Link>
        <Link
          to="/testimonials"
          className="navbar-link"
          onClick={handleLinkClick}
        >
          <button>Testimonials</button>
        </Link>
        <Link to="/contact" className="navbar-link" onClick={handleLinkClick}>
          <button>Contact</button>
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
